
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_2.svg'
import shape2 from '../../../images/shapes/shape_line_3.svg'
import shape3 from '../../../images/shapes/shape_line_4.svg'
import shape4 from '../../../images/shapes/shape_space_3.svg'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import TechnologySection from '../TechnologySection/TechnologySection';
import Testimonial from '../Testimonial/Testimonial';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="process_technology_review_section bg-light section_decoration">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="heading_block">
                            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                Proceso de Trabajo
                            </div>
                            <h2 className="heading_text mb-0">
                                Nuestro <mark>Enfoque</mark>
                            </h2>
                        </div>
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="1">
                                    01. Discovery
                                </AccordionHeader>
                                <AccordionBody accordionId="1" className='acc_body'>
                                    <p className="m-0">
                                        En la <b>Fase de Discovery</b>, nos enfocamos en definir los objetivos del proyecto y entender las necesidades de los stakeholders a través de investigaciones de mercado y reuniones. Recopilamos requisitos, creamos perfiles de usuario y desarrollamos prototipos iniciales. También evaluamos las tecnologías adecuadas y elaboramos un informe de descubrimiento que guiará las siguientes fases del desarrollo, asegurando una alineación clara y una base sólida para el proyecto.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="2">
                                   02. Diseño y Desarrollo
                                </AccordionHeader>
                                <AccordionBody accordionId="2" className='acc_body'>
                                    <p className="m-0">
                                        En la <b>Fase de Diseño y Desarrollo</b>, transformamos los requisitos recopilados en soluciones concretas. Creamos maquetas y prototipos visuales que reflejan la experiencia del usuario y la interfaz del software. Una vez aprobados, iniciamos el desarrollo, aplicando metodologías ágiles para asegurar una entrega continua y la capacidad de realizar ajustes en tiempo real. Durante esta fase, mantenemos una comunicación constante con los stakeholders para garantizar que el producto final cumpla con sus expectativas y necesidades.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="3">
                                    03. Pruebas y Aseguramiento de Calidad(QA)
                                </AccordionHeader>
                                <AccordionBody accordionId="3" className='acc_body'>
                                    <p className="m-0">
                                    En la <b>Fase de Pruebas y Aseguramiento de Calidad (QA)</b>, llevamos a cabo un exhaustivo proceso de verificación para asegurar que el software funcione según lo previsto y cumpla con los estándares de calidad establecidos. Esto incluye la realización de pruebas funcionales, pruebas de rendimiento, pruebas de seguridad y pruebas de usabilidad. Utilizamos herramientas automatizadas y manuales para detectar y corregir errores antes del lanzamiento. Nuestro equipo de QA se enfoca en identificar posibles mejoras y garantizar que la experiencia del usuario sea fluida y satisfactoria. Al final de esta fase, proporcionamos informes detallados de los resultados de las pruebas, asegurando que el producto final esté listo para su implementación en producción.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="4">
                                    04. Implementación
                                </AccordionHeader>
                                <AccordionBody accordionId="4" className='acc_body'>
                                    <p className="m-0">
                                        En la <b>Fase de Implementación</b>, llevamos a cabo el lanzamiento del software en el entorno de producción. Esto implica la instalación y configuración del sistema, así como la migración de datos y la integración con otros servicios existentes. Trabajamos en estrecha colaboración con el equipo del cliente para asegurar que todo funcione sin problemas, realizando pruebas finales para garantizar que el software esté listo para su uso. Además, ofrecemos formación y documentación a los usuarios para facilitar la transición y asegurar que aprovechen al máximo las nuevas herramientas implementadas. Nuestro objetivo es hacer que la implementación sea lo más fluida posible, minimizando cualquier interrupción en las operaciones diarias.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="5">
                                    05. Mantenimiento
                                </AccordionHeader>
                                <AccordionBody accordionId="5" className='acc_body'>
                                    <p className="m-0">
                                    En la <b>Fase de Mantenimiento</b>, nos aseguramos de que el software funcione de manera óptima a lo largo del tiempo. Esto incluye la supervisión constante del rendimiento del sistema, la resolución de errores y la implementación de actualizaciones para adaptarse a nuevas tecnologías y requisitos de negocio. También ofrecemos soporte técnico a los usuarios, asegurando una experiencia fluida y efectiva. Nuestro objetivo es prolongar la vida útil del software, optimizando su funcionalidad y manteniendo la satisfacción del cliente a través de un servicio proactivo y receptivo.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>


                        </Accordion>
                    </div>
                    <div className="col-lg-5">
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <ul className="content_layer_group unordered_list_block text-center">
                                <li><AccordionHeader targetId="1"><span>Discovery</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="2"><span>Diseño y desarrollo</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="3"><span>Aseguramiento de Calidad(QA)</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="4"><span>Implementación</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="5"><span>Mantenimiento</span></AccordionHeader></li>
                            </ul>
                        </Accordion>
                    </div>
                </div>
                <TechnologySection/>
                <Testimonial/>
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="OT Consulting Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="OT Consulting Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="OT Consulting Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="OT Consulting Shape" />
            </div>
        </section>
    )
};
export default FaqSection;
