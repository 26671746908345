import axios from 'axios';

const apis = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: apis + '/ContactoWEB',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'text/plain',
  },
});

export const sendContact = async (contacto, email, telefono, mensaje, empresa) => {
  try {
    const response = await api.post('/contacto-web', {
      contacto,
      email,
      telefono,
      mensaje,
      empresa
    });
    return response.data;
  } catch (error) {
    console.error('Error al enviar contacto:', error);
    throw error;
  }
};
