import React from 'react'
import ContactForm from '../ContactFrom/ContactForm'
import icon1 from '../../images/icons/icon_map_mark_2.svg'
import icon2 from '../../images/icons/icon_calling_2.svg'
import icon3 from '../../images/icons/icon_mail_3.svg'
import icon4 from '../../images/icons/icon_calendar_2.svg'


const ContactSection = (props) => {
    return (
        <section className="contact_section section_space bg-light">
            <div className="container">
                <div className="contact_info_box row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon1} alt="Map Mark SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">¿Donde estámos?</h3>
                                <p className="mb-0">
                                    C/ arenal, 20 -1º dcha, Madrid
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon2} alt="Calling SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Llámanos</h3>
                                <p className="mb-0">+34 627 318 514</p>
                                {/* <div className="mb-0">+88(0) 555-01117</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon3} alt="User Check SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Envianos un Correo</h3>
                                <p className="mb-0"><a href="mailto:contacto@originaltwinsconsulting.com">Contáctanos</a></p>
                                {/* <p className="mb-0">gmail.@example.com</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon4} alt="Calendar SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Programa tu visita</h3>
                                <p className="mb-0">Lunes - Viernes: 8.00-5.00</p>
                                {/* <p className="mb-0">Sabados, domingos y feriados: Cerrado</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_space pb-0">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-7">
                            <div className="contact_form mb-0">
                                <h3 className="details_item_info_title mb-1">Envianos un mensaje</h3>
                                <p className="mb-5">
                                    Danos la oportunidad de servir y traer magia a tu marca.
                                </p>
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="gmap_canvas ps-lg-5">
                                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1518.8016804102178!2d-3.707944230405982!3d40.41763679821498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42287ea6430a53%3A0xdfa6422bfcc6bd57!2sC.%20del%20Arenal%2C%2020%2C%20Centro%2C%2028013%20Madrid!5e0!3m2!1ses!2ses!4v1731112956259!5m2!1ses!2ses"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection;