
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_space_4.svg'
import shape2 from '../../../images/shapes/shape_angle_3.webp'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq_section section_decoration">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                        F.A.Q.
                    </div>
                    <h2 className="heading_text mb-0">
                        ¿Necesitas <mark>soporte?</mark>
                    </h2>
                </div>

                <div className="faq_accordion accordion" id="faq_accordion">
                    <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="1">
                                Q. ¿Cómo elegir una empresa de desarrollo de software?
                            </AccordionHeader>
                            <AccordionBody accordionId="1" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                    Una empresa de desarrollo de software a medida es un proveedor que crea software único desde cero. Además, estos proveedores ofrecen una variedad de otros servicios útiles como actualizaciones de software. Aquí hay una lista ampliada de servicios:
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="icon_list unordered_list_block">
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Desarrollo de aplicaciones web y móviles
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Arquitectura de software
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Consultoría y auditoría de TI
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Modernización de sistemas legados
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="icon_list unordered_list_block">
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Computación en la nube
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    QA y pruebas
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Análisis de negocio
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Servicios de personal de TI
                                                </span>
                                            </li>
                                        </ul>
                                        
                                    </div>
                                    <p><b>Nosotros brindamos todos estos servicios !</b></p>
                                </div>
                            </AccordionBody>

                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="2">
                                P. ¿Qué es una empresa de desarrollo de software personalizado?
                            </AccordionHeader>
                            <AccordionBody accordionId="2" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                    Una empresa de desarrollo de software personalizado se especializa en crear aplicaciones a medida que se ajustan a las necesidades específicas de sus clientes. Además, ofrecen servicios complementarios como consultoría, auditoría, modernización de sistemas heredados y pruebas de calidad para garantizar el éxito del software. Su enfoque se centra en proporcionar soluciones innovadoras y efectivas.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="3">
                                Q. ¿Por qué las empresas necesitan desarrollo de software personalizado?
                            </AccordionHeader>
                            <AccordionBody accordionId="3" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Las empresas necesitan desarrollo de software personalizado para satisfacer sus necesidades específicas y mejorar la eficiencia operativa. Este enfoque les permite contar con soluciones a medida que se adaptan a sus procesos, lo que impulsa la productividad y la competitividad. Además, el software personalizado facilita la integración con otros sistemas y tecnologías, optimizando así la experiencia del usuario.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="4">
                                Q. ¿Cuánto cuesta el desarrollo de software personalizado?
                            </AccordionHeader>
                            <AccordionBody accordionId="4" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                El costo del desarrollo de software personalizado varía según la complejidad del proyecto, la ubicación del equipo de desarrollo y las tecnologías utilizadas. 
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="5">
                                Q. ¿Por qué es importante el desarrollo de software personalizado?
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                El desarrollo de software personalizado es crucial porque permite a las empresas crear soluciones específicas que se adaptan a sus necesidades únicas. Esto no solo mejora la eficiencia operativa, sino que también ofrece una ventaja competitiva al facilitar la innovación y la adaptación a cambios en el mercado. Además, el software a medida puede integrarse mejor con los sistemas existentes, optimizando así los recursos.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="OT Consulting Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="OT Consulting Shape Angle" />
            </div>
        </section>
    )
};
export default FaqSection;
