import sImg1 from '../images/services/service_image_1.webp'
import sImg2 from '../images/services/service_image_2.webp'
import sImg3 from '../images/services/service_image_3.webp'
import sImg4 from '../images/services/service_image_4.webp'
import sImg5 from '../images/services/service_image_5.webp'

import icon1 from '../images/icons/icon_code.svg'
import icon2 from '../images/icons/icon_programming_tree.svg'
import icon3 from '../images/icons/icon_monitor_2.svg'
import icon4 from '../images/icons/icon_phone.svg'
import icon5 from '../images/icons/icon_bug.svg'
import icon6 from '../images/icons/icon_programming.svg'

import icon7 from '../images/icons/icon_analisis_2.svg'
import icon8 from '../images/icons/icon_process.svg'
import icon9 from '../images/icons/icon_chart_2.svg'
import icon10 from '../images/icons/icon_meeting.svg'
import icon11 from '../images/icons/icon_bulb_2.svg'
import icon12 from '../images/icons/icon_speed_miter.svg'



const Services = [
    {
        Id: '1',
        sImg:sImg1,
        title: 'Servicios de Gestión de TI',
        slug: 'IT-Management-Services',
        thumb1:'Strategy',
        thumb2:'Consultation',
        col:'col-lg-6',
        description:'Visit new places to discover with a Tourist Visa. We deliver your documents ...',
    },
    {
        Id: '2',
        sImg:sImg2,
        title: 'Seguimiento de Datos y Seguridad',
        slug: 'Data-Tracking-and-Security',
        thumb1:'Management',
        thumb2:'Transfer',
        col:'col-lg-6',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '3',
        sImg:sImg3,
        title: 'Desarrollo de Sitios Web',
        slug: 'Website-Development',
        thumb1:'Landing Page',
        thumb2:'Plugins',
        col:'col-lg-4',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '4',
        sImg:sImg4,
        title: 'Solución Tecnológica Moderna',
        slug: 'Modern-Technology-Solution',
        thumb1:'Consultation',
        thumb2:'solution',
        col:'col-lg-4',
        description:'Embarking on a journey of higher education in a foreign country opens doors to...',
    },
    {
        Id: '5',
        sImg:sImg5,
        title: 'Servicios de Diseño UI/UX',
        slug: 'UI-UX-Design-Services',
        thumb1:'Website',
        thumb2:'Mobile App',
        col:'col-lg-4',
        description:'Expert Guidance for a Seamless Immigration Journey Expert Guidance...',
    },
    {
        Id: '6',
        sImg:icon1,
        title: 'Desarrollo de Software Personalizado',
        slug: 'Custom Software Development',
        features: ['Diseño de arquitectura de software', 'Servicios de integración de sistemas', 'Servicios de migración de datos', 'Modernización de aplicaciones heredadas']
    },
    {
        Id: '7',
        sImg:icon2,
        title: 'Servicios de Auditoría y Consultoría IT',
        slug: 'Audit-&-IT-Consulting-Services',
        features: ['Auditoría de Seguridad de TI', 'Auditoría de Seguridad de Aplicaciones', 'Auditoría de Seguridad de Datos', 'Auditoría de Cumplimiento de Normativas']
    },
    {
        Id: '8',
        sImg:icon3,
        title: 'Diseño y Desarrollo de Aplicaciones Web',
        slug: 'Web-Application-Design-and-Development',
        features:['Servicios de desarrollo de aplicaciones web', 'Servicios de desarrollo de portales web', 'Servicios de desarrollo de sitios web', 'Desarrollo web offshore']
    },
    {
        Id: '9',
        sImg:icon4,
        title: 'Diseño y Desarrollo de Aplicaciones Móviles',
        slug: 'Mobile-App-Design-and-Development',
        features:['Servicios de desarrollo para Android', 'Servicios de desarrollo de aplicaciones para iOS', 'Servicios de diseño de aplicaciones móviles', 'Desarrollo de aplicaciones móviles para empresas']
    },
    {
        Id: '10',
        sImg:icon5,
        title: 'Servicios de Diseño UI/UX',
        slug: 'Best-UI/UX-Design-Services',
        features: ['Diseño Web Responsivo', 'Diseño de Interfaz de Usuario', 'Diseño de Experiencia de Usuario', 'Diseño de Prototipos', 'Diseño de Landing Page']
    },
    {
        Id: '11',
        sImg:icon6,
        title: 'Mantenimiento y Soporte al Cliente',
        slug: 'Maintenance-and-Customer-Support',
        features: ['Mantenimiento Correctivo', 'Mantenimiento Preventivo', 'Mantenimiento Predictivo', 'Mantenimiento Evolutivo', 'Mantenimiento Adaptativo']
    },
    {
        Id: '12',
        sImg:icon7,
        title: 'Planificación y Ejecución Estratégica',
        slug: 'Strategic-Planning-and-Execution',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '13',
        sImg:icon8,
        title: 'Optimización de Procesos Empresariales',
        slug: 'Business-Process-Optimization',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '14',
        sImg:icon9,
        title: 'Consultoría en Transformación Digital',
        slug: 'Digital-Transformation-Consulting',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '15',
        sImg:icon10,
        title: 'Planificación y Ejecución Estratégica',
        slug: 'Strategic-Planning-and-Executions',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '16',
        sImg:icon11,
        title: 'Soluciones de Gestión del Cambio',
        slug: 'Change-Management-Solutions',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
    {
        Id: '17',
        sImg:icon12,
        title: 'Desarrollo de Métricas de Rendimiento y KPI',
        slug: 'Performance-Metrics-and-KPI-Development',
        description:'Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.',
    },
]    

export default Services;